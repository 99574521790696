<template>
  <el-scrollbar class="live-data-all">
    <div class="live-data-all_title">学生直播</div>

    <div class="live-data-all_content">
      <div class="content-operate">
        <el-button type="primary" @click="$router.back()">返回</el-button>
      </div>

      <template v-if="!isRefresh">
        <div class="live-item" v-for="item in liveData" :key="item.id">
          <div class="live-item_play" @click="handleLiveDetail(item)">
            <div class="play-status no-start" v-if="!item.getStudentInfo.getOnliveRoom">
              <span>未开始</span>
            </div>

            <div
              :class="[
                'play-status',
                liveState[item.getStudentInfo.getOnliveRoom.status].class,
                { disconnect: item.getStudentInfo.getOnliveRoom.is_disconnect === 1 },
              ]"
              v-else
            >
              <i
                class="iconfont"
                v-if="item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_disconnect === 0"
              >
                &#xe620;
              </i>
              <i class="iconfont" v-else-if="item.getStudentInfo.getOnliveRoom.status === 2">&#xe63f;</i>

              <span v-if="item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_disconnect === 1">
                掉线中...
              </span>
              <span v-else>{{ liveState[item.getStudentInfo.getOnliveRoom.status].label }}</span>
            </div>

            <img src="../../assets/image/default-live-no-start-bg.png" alt="" v-if="!item.getStudentInfo.getOnliveRoom" />

            <img
              :src="item.getStudentInfo.getOnliveRoom.live_background"
              alt=""
              v-else-if="item.getStudentInfo.getOnliveRoom.status === 0"
            />

            <!-- 直播回放 -->
            <img :src="getLiveRoomImg(item.getStudentInfo)" alt="" v-else-if="item.getStudentInfo.getOnliveRoom.status === 2" />

            <img src="../../assets/image/default-live-pause.png" alt="" v-else-if="item.getStudentInfo.getOnliveRoom.status === 3" />

            <!-- 掉线 -->
            <img
              src="../../assets/image/default-live-disconnection.png"
              alt=""
              v-else-if="item.getStudentInfo.getOnliveRoom.is_disconnect === 1"
            />

            <video
              :id="`live_${item.getStudentInfo.getLiveUrl[0].user_id}_${timeStamp}`"
              muted
              autoplay
              loop
              v-else-if="item.getStudentInfo.getOnliveRoom.status === 1"
            ></video>
          </div>

          <div class="live-item_title">
            {{ item.getStudentInfo.getOnliveRoom ? item.getStudentInfo.getOnliveRoom.title : "" }}
          </div>

          <div class="live-item_user">
            <template v-if="item.getStudentInfo.getOnliveRoom">
              <div class="user-info">
                <img :src="getUserImg(item.getStudentInfo.getOnliveRoom.avatar)" alt="" />
                <div class="user-info-name">{{ item.getStudentInfo.getOnliveRoom.nickname }}</div>
              </div>
            </template>

            <template v-else>
              <div class="user-info">
                <img :src="getUserImg(item.avatar)" alt="" />
                <div class="user-info-name">{{ item.getStudentInfo.nickname }}</div>
              </div>
            </template>

            <div class="user-hot">
              <i class="iconfont">&#xe66a;</i>
              <span>
                {{ item.getStudentInfo.getOnliveRoom && item.getStudentInfo.getOnliveRoom.status !== 0 ? getRandomValue() : 0 }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="live-data-all_pagination">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.page"
        :page-size="pageParams.limit"
        layout="prev, pager, next, jumper"
        :total="pageParams.total"
      >
      </el-pagination>
    </div>
  </el-scrollbar>
</template>

<script>
import { teacherTrainRoomList, getOtherLive } from "@/utils/apis";

export default {
  data() {
    return {
      liveData: [],
      pageParams: {
        page: 1,
        limit: 10,
        total: 0,
      },
      liveState: {
        0: { label: "未开播", class: "no-aired" },
        1: { label: "直播中...", class: "pending" },
        2: { label: "直播回放", class: "over" },
        3: { label: "直播暂停", class: "pause" },
      },
      timeStamp: new Date().getTime(),
      isRefresh: false,
    };
  },

  mounted() {
    this.getLiveData();
  },

  methods: {
    getLiveData() {
      const scrollDom = document.querySelector(".live-data-all .el-scrollbar__wrap");
      if (scrollDom) {
        scrollDom.scrollTop = 0;
      }

      const params = {
        train_id: this.$route.query.id,
        page: this.pageParams.page,
        limit: this.pageParams.limit,
      };

      teacherTrainRoomList(params)
        .then((res) => {
          if (res.code == 200) {
            this.liveData = res.data.list || [];
            this.pageParams.total = res.data.total || 0;
            this.isRefresh = false;

            this.$nextTick(() => {
              this.showLiveVideo();
            });
          }
        })
        .catch((err) => {
          console.error("err: ", err);
        });
    },

    // 直播视频拉流
    showLiveVideo() {
      const pengdingLive = this.liveData.filter((item) => {
        if (!item.getStudentInfo.getOnliveRoom) return false;

        return item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_disconnect === 0;
      });

      pengdingLive.forEach((item) => {
        const idName = `live_${item.getStudentInfo.getLiveUrl[0].user_id}_${this.timeStamp}`;
        const webRTC = item.getStudentInfo.getLiveUrl[0].webrtc_rtmp;
        const m3u8Str = item.getStudentInfo.getLiveUrl[0].http_two_rtmp;

        const player = TCPlayer(idName, {
          sources: [
            {
              // 快直播地址
              src: webRTC,
            },
            {
              // HLS直播地址
              src: m3u8Str,
            },
          ],
          licenseUrl: "https://license.vod2.myqcloud.com/license/v2/1305294767_1/v_cube.license",
          autoplay: true,
          poster: item.getStudentInfo.getOnliveRoom.live_background,
          controls: false,
        });
        player.on("error", (err) => {
          console.log(err);
          if (!this.isRefresh) {
            this.isRefresh = true;
            this.timeStamp = new Date().getTime();
            this.getLiveData();
          }
        });

        this.$nextTick(() => {
          player.play();
        });
      });
    },

    // 获取头像，没有使用默认图片
    getUserImg(avatar) {
      return avatar ? avatar : require("assets/image/blank_headPic.png");
    },

    // 随机热度，100w-500w
    getRandomValue() {
      const hotValue = Math.random() * 400 + 100;

      return `${hotValue.toFixed(2)}w`;
    },

    handleCurrentChange(val) {
      this.pageParams.page = val;
      this.timeStamp = new Date().getTime();

      this.getLiveData();
    },

    // 查看直播详情
    async handleLiveDetail(row) {
      console.log("live detail row: ", row);
      let url = "";
      const liveStatus = row.getStudentInfo.getOnliveRoom ? row.getStudentInfo.getOnliveRoom.status : -1;

      if ([-1, 0].includes(liveStatus)) {
        // 未创建直播间 ,  未开播
        url = this.$router.resolve({
          path: "/liveData/no-started",
          query: {
            id: this.$route.query.id,
            end_time: this.$route.query.end_time,
            nickname: row.getStudentInfo.getOnliveRoom ? row.getStudentInfo.getOnliveRoom.nickname : row.getStudentInfo.nickname,
            avatar: row.getStudentInfo.getOnliveRoom ? row.getStudentInfo.getOnliveRoom.avatar : row.avatar,
            room_id: row.getStudentInfo.getOnliveRoom ? row.getStudentInfo.getOnliveRoom.id : 0,
          },
        }).href;
      } else if ([1, 3].includes(liveStatus)) {
        // 直播中，直播暂停
        const trainInfo = {
          advert_id: null,
          script_id: null,
        };

        const res = await getOtherLive({
          train_id: this.$route.query.id,
          paging: 1,
          page: 1,
          pageSize: 1,
        });

        if (res.code === 200) {
          if (res.data && res.data.list && res.data.list.length > 0) {
            trainInfo.advert_id = res.data.list[0].train_info.advert_id;
            trainInfo.script_id = res.data.list[0].train_info.script_id;
          }
        }

        // 直播中
        url = this.$router.resolve({
          path: "/liveData/detail",
          query: {
            roomId: row.getStudentInfo.getOnliveRoom.id,
            userId: row.getStudentInfo.id,
            trainId: this.$route.query.id,
            advertId: trainInfo.advert_id,
            script_id: trainInfo.script_id,
            end_time: this.$route.query.end_time,
            webrtc: row.getStudentInfo.getLiveUrl[0].webrtc_rtmp,
            m3u8: row.getStudentInfo.getLiveUrl[0].http_two_rtmp,
          },
        }).href;
      } else if (liveStatus === 2) {
        // 直播回放
      }

      if (!!url) window.open(url, "_blank");
    },

    getLiveRoomImg(studentInfo) {
      const pic = studentInfo.getLiveUrl[0].pic;

      return pic ? pic : studentInfo.getOnliveRoom.live_background;
    },
  },
};
</script>

<style lang="scss" scoped>
.live-data-all {
  width: 100%;
  height: 100vh;
  background-color: #080b32;
  overflow: hidden;
  padding: 10px 40px;
  box-sizing: border-box;

  ::v-deep .el-scrollbar__wrap {
    min-width: 1412px;
    margin: 0 !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &_title {
    font-weight: 800;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 40px;
  }

  &_content {
    // height: calc(100vh - 144px);
    box-sizing: border-box;
    display: flex;
    gap: 30px 20px;
    position: relative;
    flex-wrap: wrap;

    .content-operate {
      position: absolute;
      top: -60px;
      left: 0px;
    }

    .live-item {
      aspect-ratio: 352 / 529;
      box-sizing: border-box;
      padding-bottom: 16px;
      background-color: #0c0e3f;
      border-radius: 8px;
      width: calc((100% - 80px) / 5);
      flex-shrink: 0;
      flex-grow: 0;

      &_play {
        background-color: #ffffff27;
        height: calc(100% - 91px);
        border-radius: 8px;
        margin-bottom: 16px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .play-status {
          position: absolute;
          top: 0;
          right: 0;
          padding: 2px 6px;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          z-index: 999;

          .iconfont {
            font-size: 12px;
            margin-right: 2px;
          }

          &.pending,
          &.pause {
            background-color: #4741ff99;
          }

          &.over {
            background-color: #ff414498;
          }

          &.no-aired,
          &.no-start,
          &.disconnect {
            background-color: #e2e2e299;
          }
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        ::v-deep .video-js {
          width: 100%;
          height: 100%;
        }
      }

      &_title {
        padding-inline: 10px;
        margin-bottom: 12px;
        height: 25px;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
        line-height: 25px;
        white-space: nowrap; /* 防止文本换行 */
        overflow: hidden; /* 隐藏超出容器的部分 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
      }

      &_user {
        height: 30px;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        padding-inline: 10px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .user-info,
        .user-hot {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .user-info {
          width: 60%;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
          }

          .user-info-name {
            width: calc(100% - 24px);
            white-space: nowrap; /* 防止文本换行 */
            overflow: hidden; /* 隐藏超出容器的部分 */
            text-overflow: ellipsis; /* 超出部分显示省略号 */
          }
        }

        .user-hot {
          .iconfont {
            color: #19fdff;
          }
        }
      }
    }
  }

  &_pagination {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .el-pagination {
      background-color: transparent;

      button {
        background: transparent;
        color: #91a1d9;
      }

      .el-pager {
        li {
          background: transparent;
          color: #91a1d9;
          border-radius: 50%;
          min-width: 28px;

          & + li {
            margin-left: 10px;
          }

          &.active {
            color: #ffffff;
            background-color: #564ced;
          }
        }
      }

      .el-pagination__jump {
        color: #91a1d9;

        .el-input__inner {
          background: transparent;
          color: #91a1d9;
          border-color: #91a1d9;
        }
      }
    }
  }
}
</style>
