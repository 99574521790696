import { render, staticRenderFns } from "./LiveDataAll.vue?vue&type=template&id=2a15b0ef&scoped=true"
import script from "./LiveDataAll.vue?vue&type=script&lang=js"
export * from "./LiveDataAll.vue?vue&type=script&lang=js"
import style0 from "./LiveDataAll.vue?vue&type=style&index=0&id=2a15b0ef&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a15b0ef",
  null
  
)

export default component.exports